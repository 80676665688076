/* eslint-disable */

// expose to page javascript
import 'expose-loader?_!underscore'
import 'expose-loader?jQuery!expose-loader?$!jquery'
import 'expose-loader?List!list.js'

// jquery plugins
import 'jquery-ujs'
// FIXME: replace daterangepicker with something better
// importing this from vendor because it's been modified
import '../../../vendor/assets/javascripts/daterangepicker'
import 'jquery-quicksand/jquery.quicksand.js'
import 'waypoints/lib/jquery.waypoints.min'
import 'waypoints/lib/shortcuts/sticky'

import 'prismjs'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-css'
import 'prismjs/components/prism-java'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-ruby'

import 'prismjs/plugins/line-numbers/prism-line-numbers.js'
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace.js'

import ClipboardJS from 'clipboard'
import autosizeInput from 'autosize-input'

$(function() {

  function initClipboard() {
    var clipboard = new ClipboardJS('.js-stui-copy-button');

    var updateIcon = function(name, button) {
      var icon = $(button).find('.o-stui-icon use');
      var originalIconName = icon.attr('xlink:href');
      icon.attr('xlink:href', '#' + name);
      setTimeout(function() {
        icon.attr('xlink:href', originalIconName);
      }, 3000);
    };

    clipboard.on('success', function(e) {
      updateIcon('success-fill', e.trigger);
    });

    clipboard.on('error', function(e) {
      updateIcon('error', e.trigger);
    });
  };
  initClipboard();

  $('.js-stui-copy-field').each(function() {
    autosizeInput($(this)[0]);
    $(this).on('click', function() {
      $(this).select();
    });
  });

  initAccountNavigation();
  initEngineNavigation();

  function initAccountNavigation() {
    var VISIBLE_CLASS = 'stui-layout-account-sidebar--expanded';
    var $accountNavShowControl = $('.js-expand-account-sidebar');
    var $accountNavHideControl = $('.js-condense-account-sidebar');
    var $accountNavContainer = $('.stui-layout-account-sidebar');
    var $accountNavInnerContainer = $('.c-stui-account-navigation-container');
    var $logoutButton = $('.c-stui-current-account__logout');

    // Initialize tabindex for a closed sidebar on load
    $accountNavShowControl.attr('tabindex', 0);
    $accountNavHideControl.attr('tabindex', -1);
    $accountNavInnerContainer.find('a').attr('tabindex', -1);
    $logoutButton.attr('tabindex', -1);

    // If we are on the account view, set the tabindex for a static expanded sidebar
    $('.stui-layout--account').find('.c-stui-account-navigation-container').find('a').attr('tabindex', 0);
    $('.stui-layout--account').find('.c-stui-current-account__logout').attr('tabindex', 0);

    $accountNavShowControl.on('click', function(e) {
      e.stopPropagation();
      $accountNavInnerContainer.find('a').attr('tabindex', 0);
      $accountNavHideControl.attr('tabindex', 0);
      $logoutButton.attr('tabindex', 0);
      $accountNavContainer.addClass(VISIBLE_CLASS);
    });

    $accountNavShowControl.on('keypress', function(e) {
      if(e.keyCode == 13) {
        $(this).trigger('click');
      }
    });

    $accountNavHideControl.on('click', function() {
      $accountNavInnerContainer.find('a').attr('tabindex', -1);
      $accountNavHideControl.attr('tabindex', -1);
      $logoutButton.attr('tabindex', -1);
      $accountNavContainer.removeClass(VISIBLE_CLASS);
    });

    $accountNavHideControl.on('keypress', function(e) {
      if(e.keyCode == 13) {
        $(this).trigger('click');
      }
    });

    $accountNavContainer.on('click', function(e) {
      e.stopPropagation();
    });

    $(document).on('click', function() {
      if ($('.stui-layout').hasClass('stui-layout--engine')) {
        $accountNavInnerContainer.find('a').attr('tabindex', -1);
        $accountNavHideControl.attr('tabindex', -1);
        $logoutButton.attr('tabindex', -1);
        $accountNavContainer.removeClass(VISIBLE_CLASS);
      }
    });
  }

  function initEngineNavigation() {
    var VISIBLE_CLASS = 'stui-layout-all-engines-navigation-container--visible';
    var $engineNavShowControl = $('.js-show-engine-navigation');
    var $engineNavHideControl = $('.js-hide-engine-navigation');
    var $engineNavContainer = $('.stui-layout-all-engines-navigation-container');

    $engineNavShowControl.on('click', function(e) {
      e.stopPropagation();
      $engineNavContainer.addClass(VISIBLE_CLASS);
    });

    $engineNavHideControl.on('click', function() {
      $engineNavContainer.removeClass(VISIBLE_CLASS);
    });

    $engineNavContainer.on('click', function(e) {
      e.stopPropagation();
    });

    $(document).on('click', function() {
      $engineNavContainer.removeClass(VISIBLE_CLASS);
    });
  }

  $('.o-stui-hidden-text').each(function() {
    var $self = $(this);
    var HIDDEN_CLASS = 'o-stui-hidden-text__content--hidden';
    var $control = $('.o-stui-hidden-text__control', $self);
    var $content = $('.o-stui-hidden-text__content', $self);
    var visible = !$content.hasClass(HIDDEN_CLASS);

    function showText() {
      visible = true;
      $content.removeClass(HIDDEN_CLASS);
      $('svg use', $control).attr('xlink:href', '#hide-fill');
    }

    function hideText() {
      visible = false;
      $content.addClass(HIDDEN_CLASS);
      $('svg use', $control).attr('xlink:href', '#show-fill');
    }

    $control.on('click', function() {
      if (visible) {
        hideText();
      } else {
        showText();
      }
    });
  });

  let viewHeaderFixed = false;
  let headerTestTries = 0;
  const headerTestLimit = 30;
  const retryRate = 50;
  const viewBannerVisible = $('.stui-layout--banner').length;

  !function headerTest() {
    if ($('.stui-layout--engine .c-stui-view-header').length > 0) {
      new Waypoint.Sticky({
        offset: viewBannerVisible ? 50 : 0,
        element: $('.c-stui-view-header')[0],
        wrapper: '<div class="js-stui-view-header-sticky-wrapper" />',
        stuckClass: 'c-stui-view-header--stuck',
        handler: function (direction) {
          if (direction == 'down') {
            $('.stui-layout-utility-header').addClass('stui-layout-utility-header--stuck');
            viewHeaderFixed = true;
          } else {
            $('.stui-layout-utility-header').removeClass('stui-layout-utility-header--stuck');
            $('.c-stui-view-header .c-stui-header__container').css('width', '100%');
            viewHeaderFixed = false;
          }
        }
      });
    }
    else {
      headerTestTries++;
      if (headerTestTries <= headerTestLimit) {
        setTimeout(headerTest, retryRate);
      }
    }
  }();

  var stickyReferenceUI = !$('.reference-ui-svg-container').length ? null : new Waypoint.Sticky({
    element: $('.reference-ui-svg-container')[0],
    wrapper: '<div class="reference-ui-sticky-wrapper" />',
    stuckClass: 'reference-ui--stuck',
    offset: 20
  });

  (function($) {
    $.fn.stuiModal = function(options) {
      return this.each(function() {
        // --------------------------------------------------
        // Options
        // --------------------------------------------------
        // triggeredBy: js selector that triggers the modal on click
        // show: if true, show the modal on page load
        // showBackdrop: if true, show a backdrop behind the modal
        // allowTriggeredByEventPropagation: if true, allows the triggeredBy on-click event to propagate

        // --------------------------------------------------
        // Events
        // --------------------------------------------------
        // modalVisible: triggered when the modal is visible
        // modalHidden: triggered when the modal is hidden

        var $self = $(this);
        var settings = $.extend({
          triggeredBy: null,
          show: false,
          showBackdrop: true,
          allowTriggeredByEventPropagation: false,
          visible: function(event) {
            $self.trigger('modalVisible', event);
          },
          hidden: function(event) {
            $self.trigger('modalHidden', event);
          }
        }, options);

        function createModalOverlayMask() {
          if (!$('.js-stui-overlay-mask').length) {
            var $backdrop = $('<div class="c-stui-overlay-mask js-stui-overlay-mask u-stui-display" />');
            $('body').append($backdrop);
            setTimeout(function() {
              $backdrop.addClass('u-stui-visible');
            }, 10);
          }
        }

        function destroyModalBackdrop() {
          var $backdrop = $('.js-stui-overlay-mask');
          $backdrop.removeClass('u-stui-visible');
          setTimeout(function() {
            $backdrop.remove();
          }, 300);
        }

        function showModal(event) {
          $self.addClass('u-stui-display');
          settings.visible.call(this, event);
          setTimeout(function() {
            $self.addClass('u-stui-visible');
            if (settings.showBackdrop) {
              createModalOverlayMask();
            }
          }, 10);
        }

        function hideModalKeepBackdrop(event) {
          $self.removeClass('u-stui-visible');
          setTimeout(function() {
            $self.removeClass('u-stui-display');
            settings.hidden.call(this, event);
          }, 300);
        }

        function hideModal(event) {
          hideModalKeepBackdrop(event);
          if (settings.showBackdrop) {
            destroyModalBackdrop();
          }
        }

        // Show the modal on page load if the option is set
        if (settings.show) {
          $(function() {
            showModal();
          })
        }

        // Show the modal if the trigger is clicked
        if (settings.triggeredBy !== null) {
          $(settings.triggeredBy).on('click', function(e) {
            if (!settings.allowTriggeredByEventPropagation) {
              e.preventDefault();
              e.stopPropagation();
            }
            showModal(e);
          });
        }

        // Hide the modal when any hide action is clicked
        $(document).on('click', '.c-stui-modal .o-stui-close, .c-stui-modal .js-close', function(e) {
          e.preventDefault();
          e.stopPropagation();
          hideModal(e);
        });

        // Hide the modal when clicking anywhere outside of it
        $(document).on('click', function(e) {
          if (!$(e.target).closest('.c-stui-modal').length) {
            hideModal(e);
          }
        });

        $(document).on('keyup', function(e) {
          if (e.keyCode === 27) {
            hideModal(e);
          }
        });

        $(document).on('modalHide', $self, function(e) {
          hideModal(e);
        })

        // Watch out, this is stupid: use when hiding one modal and showing another
        $self.on('hideModalKeepBackdrop', function(e) {
          hideModalKeepBackdrop(e);
        })
      });
    }
  })(jQuery);
})
